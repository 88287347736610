/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {

		// All pages
		'common': {

			navOpen: false,

			toggleNav: function() {

				this.navOpen = !this.navOpen;

				if(this.navOpen) {

					$('header').addClass('open');
					$('header button div').addClass('open');

				} else {
					
					$('header').removeClass('open');
					$('header button div').removeClass('open');

				}


			},
			init: function() {

				if($('header button')) {

					$('header button').click(this.toggleNav);
					
				}

			},
			finalize: function() {

				$.validator.methods.email = function(value, element) {

					var patt = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return this.optional(element) || patt.test(value);

				};

			}

		},

		// Home page
		'home': {

			init: function() {

				// JavaScript to be fired on the home page

			},
			finalize: function() {

				// JavaScript to be fired on the home page, after the init JS

			}

		},

		'page_template_template_apply_php': {

			init: function() {

				// JavaScript to be fired on the about us page

			},

			finalize: function() {

				$('input, textarea').focus(function() {

					$('label[for="' + $(this).attr('name') + '"]').addClass('active');

				});

				$('input, textarea').focusout(function() {

					if(!$(this).val()) {
						$('label[for="' + $(this).attr('name') + '"]').removeClass('active');
					}

				});

				$('#apply-now').validate({

					submitHandler: function(form) {

						$('#apply-now button[type="submit"]').hide();
						$(form).ajaxSubmit({
							target: '#form-feedback',
							success: function() { 

								$('#form-feedback').addClass('show'); 

							}
						});
						return false;

					},
					invalidHandler: function(event, validator) {},
					errorElement: 'span',
					rules: {
						name: {
							required: true
						},
						email: {
							required: true,
							email: true,
						},
						emailrepeat: {
							required: true,
							equalTo: '#email'
						},
						message: {
							required: true
						}
					}

				});

			}

		},
		'page_template_template_contact_php': {

			init: function() {

				// JavaScript to be fired on the contact us page

			},

			finalize: function() {

				$('input, textarea').focus(function() {

					$('label[for="' + $(this).attr('name') + '"]').addClass('active');

				});

				$('input, textarea').focusout(function() {

					if(!$(this).val()) {
						$('label[for="' + $(this).attr('name') + '"]').removeClass('active');
					}

				});

				$('#contact-us').validate({

					submitHandler: function(form) {

						$('#contact-us button[type="submit"]').hide();
						$(form).ajaxSubmit({
							target: '#contact-us #form-feedback',
							success: function() { 

								$('#contact-us #form-feedback').addClass('show'); 

							}
						});
						return false;

					},
					invalidHandler: function(event, validator) {},
					errorElement: 'span',
					rules: {
						name: {
							required: true
						},
						email: {
							required: true,
							email: true,
						},
						emailrepeat: {
							required: true,
							equalTo: '#email'
						},
						message: {
							required: true
						}
					}

				});

			}

		}

	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {

		fire: function(func, funcname, args) {

			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {

				namespace[func][funcname](args);

			}

		}, loadEvents: function() {

			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');

		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

